<template>
  <section
    class="configure-document"
    :class="{ mobile: getIsMobile, 'is-close-RS': !getIsRSOpen }"
    id="drag-template-background"
    v-loading="loading"
    element-loading-text="Loading..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <section>
      <div class="inner-navbar">
        <div class="inner-navbar-2nd-level-child">
          <div class="inner-navbar-1th-level-child flex-direction-row">
            <div style="display: flex; justify-content: space-between">
              <el-button
                @click="goBack"
                slot="reference"
                class="rounded-0 back-btn type-2"
                style="margin-top: 4px; height: 38px"
              >
                <i class="el-icon-back">Back</i>
              </el-button>
            </div>
            <div class="flex-fill left-block vue-form ml-2">
              <el-form class="form-title">
                  <!-- :readonly="canRename" -->
                <el-input
                  :readonly="true"
                  v-model="documentName"
                  placeholder="Enter Document Title"
                  class="input-type-one"
                  style="margin-top: -10px;"
                ></el-input>
                <p
                  class="error"
                  v-if="
                    getConfigurableDocumentErrors &&
                    getConfigurableDocumentErrors.name
                  "
                >
                  {{ getConfigurableDocumentErrors.name }}
                </p>
              </el-form>
            </div>
          </div>
          <div class="right-block flex-self-end pe-3 align-items-self">
            <el-button
              class="stbtn"
              style="
                color: #f754a2;
                background-color: #ffffff;
                border-radius: 4px;
                height: 44px;
                margin-right: 10px;
              "
              color="#F754A2"
              @click="handleDownloadCSV"
              :underline="false"
            >
              Download CSV
            </el-button>

            <div class="action-wrapper-type-two">
              <el-button
                slot="reference"
                class="rounded-0 btn-one type-2"
                type="primary"
                v-on:click="openRightSideBar()"
                :disabled="getIsRSOpen"
                v-if="!getIsRSOpen"
                >&lt;&lt;</el-button
              >
              <el-button
                slot="reference"
                class="rounded-0 btn-one close type-2"
                type="primary"
                v-on:click="closeRightSideBar()"
                v-if="getIsRSOpen"
                >&gt;&gt;</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          document-editor-container document-container-wrapper-grand-parent
        "
      >
        <!-- <el-row type="flex" justify="center"> -->
        <!-- <el-col :span="18"> -->
        <div class="document-container-wrapper-parent">
          <div class="document-container-wrapper">
            <div class="document-container">
              <el-row>
                <el-col
                  :span="20"
                  :xl="{ span: 20 }"
                  :lg="{ span: 20 }"
                  :md="{ span: 20 }"
                  :sm="{ span: 24 }"
                  :xs="{ span: 24 }"
                  class="pdf-main-area"
                >
                  <el-scrollbar
                    v-loading="documentDataLoading"
                    ref="scrollbar"
                    id="scrollbar"
                  >
                    <div class="form-builder" ref="formBuilder">
                      <div class="form-holder">
                        <div class="form-image-holder">
                          <div
                            id="wrapper"
                            style="text-align: center; margin-bottom: 50px"
                          >
                            <img :src="documentData.downloadUrl" alt="card" width="100%"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-scrollbar>
                </el-col>
              </el-row>
            </div>
            <!-- <el-col :span="16" style="width:640px;"> -->
            <!-- </el-col> -->
          </div>
          <!-- <el-col :span="6" class="position-relative"> -->
          <div class="right-side-bar">
            <div class="form-fields-set" style="padding: 0px">
              <div class="scrollable-container">
                <el-scrollbar style="padding: 0px">
                  <div class="document-fields">
                    <div class="document-fields-header-item">
                      Document Fields
                    </div>
                    <div class="document-fields-content"
                      v-if="documentData && documentData.parsed_data_Obj && documentData.parsed_data_Obj.formFields"
                    >
                      <div
                        v-for="(item, index) in documentData.parsed_data_Obj.formFields"
                        class="document-fields-item"
                        :key="index">
                        <div
                          v-if="item.value"
                          class="document-fields-item-inner"
                        >
                          <h5 class="document-fields-item-header">
                            {{item.fieldName}}
                          </h5>
                          <span class="document-fields-item-value">
                            {{item.value}}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-scrollbar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import axios from "@/config/axios";
import appConfig from "@/config/app";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

import offerLetterBuildHelper from "@/mixins/offerLetterBuildHelper";
import MobileRelatedHelper from "@/mixins/MobileRelatedHelper";
import DocumentsHelper from "@/mixins/CompanyDocumentsHelper";
//import CurrencyInput from "./vue-weblineindia-currency-input/src/component";
import ConfigureDocumentsHelper from "@/mixins/ConfigureDocumentHelper";
import "vue-anka-cropper/dist/VueAnkaCropper.css";
import EntityHelper from "@/mixins/EntitiesHelper";

export default {
  name: "Global-ConfigureEmployeeDocumentDraft",
  components: {
  },
  mixins: [
    offerLetterBuildHelper,
    MobileRelatedHelper,
    DocumentsHelper,
    ConfigureDocumentsHelper,
    EntityHelper,
  ],
  created() {
    let that = this;
    document.addEventListener("keyup", function (evt) {
      if (evt.keyCode === 46) {
        that.deleteActiveElement();
      }
    });
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (this.getIsMobile) {
      this.$store.commit("navigationOpen/setIsOpen", false, {
        root: true,
      });
      this.$store.commit("navigationOpen/setIsRSOpen", false, {
        root: true,
      });
    }
  },
  data() {
    return {
      documentData: {},
      requestDocumentId: 0,
      blanckDocumentModal: false,
      blanckDocumentData: {
        name: "",
        pages: 1,
        loading: false,
      },
      blankTemplatesUrl: [
        "/rest/esignature/files/3cc3b9ac-ff3d-4dfa-90a9-96644c719a15.pdf",
        "/rest/esignature/files/dfc42fc5-4696-4e88-81df-8daf1a7a63dd.pdf",
        "/rest/esignature/files/56647615-6ccc-49c6-9991-b72be2706148.pdf",
      ],
      uploadFromOthersFileName: "",
      uploadFromOthersBlobData: null,
      is_SaveDocument: false,
      isAspectRatio: true,
      dialogVisible: false,
      isFromFieldActivated: false,
      deltaXToMove: 0,
      deltaYToMove: 0,
      deltaXForNearestField: 4,
      deltaYForNearestField: 4,
      posStep: 1,
      posStepShift: 5,
      pageWidth: 0,
      deltaXOfPageWidth: 2,
      pageHeight: 0,
      currentUserSign: null,
      currentUserInitial: null,
      isFieldEditting: false,
      recipientSelectedColor: "",
      defined_colors: ["#FF495C", "#3777FF", "#EE964B", "#03CEA4"],

      dragItemHeight: 40,
      limit: 2,
      deltaX: 0,
      deltaY: 0,
      mouseX: 0,
      mouseY: 0,
      isDragging: false,
      tableElementLoading: false,
      activeCollapse: ["1", "2", "3"],
      activeEntityCollapse: [],
      tableLoading: false,
      elements: [],
      width: 0,
      height: 0,
      x: 0,
      y: 0,
      addItemDataDialogVisible: false,
      selectedItemIndex: null,
      selectedItem: {},
      refreshForm: false,
      selectedSignatureItem: {},
      selectedSignatureItemIndex: -1,
      captureSignatureDialog: false,
      hasBackground: false,
      documentId: null,
      configurableDocumentId: null,
      documentDataLoading: false,
      documentFormData: null,
      documentName: "",
      loading: false,
      backgroundImage: "",
      previewVisible: false,
      activatedItem: null,
      activatedItemIndex: -1,
      addMySignatureStatus: false,
      addMyInitialStatus: false,
      isAnyoneCanApprove: false,
      names: [],
      myInitial: {
        text: "",
        color: "",
        value: "",
      },
      fields: [],
      currentFormBuilderWidth: null,
      storedFormBuilderWidth: null,
      checkExistingData: false,
      backgroundImages: [
        "https://devnimblehrms.s3.ap-south-1.amazonaws.com/company-document-backgrounds/60468b2c0a7b7942d5781c36/60468b2c0a7b7942d5781c36-0.png",
      ],
      loadingElements: false,
      userInitial: "",
      selectedTemplateFields: [],
      selectedEntityFields: [],
      templatesUsed: [],
      entitiesUsed: [],
      showErrorElementDialog: false,
      showServerErrorsDialog: false,
      showDocumentUserSettings: false,
      errorElements: [],
      showDocumentExpirationDatesSettings: false,
      fieldErrorMessage: "",
      typeNameMap: {
        logo: "Company Logo",
        SINGLE_LINE_TEXT: "Single Line Input",
        NUMBER: "Number Input",
        MULTI_LINE_TEXT: "Multi line Text",
        SELECT: "DropDown",
        CHECKBOX: "CheckBox",
        DATE: "Date",
        SIGNATURE: "Signature",
        "my-signature": "My Signature",
      },
      documentUsers: [
        {
          email: "",
          first_name: "",
          last_name: "",
          e_signature_required: true,
          value: "SENDER",
          name: "SENDER",
          default: true,
          e_signature_order: 0,
        },
      ],
      enforceSignatureOrder: true,
      signaturesFields: [],
      signatureDates: [],
      signaturesConfig: [],
      submitEvent: false,
      params: {},
      expirationSettings: {
        valid_from_date: {
          is_manual: false,
          manual_type: "",
          signature_user: "",
          signature_user_post_days: "",
          admin_approve_post_days: "",
        },
        valid_to_date: {
          is_manual: false,
          manual_type: "",
          signature_user: "",
          signature_user_post_days: "",
          admin_approve_post_days: "",
        },
      },
      filechanged: false,
      options: [],
      recipientSelection: "",
      list: [],
      addDocumentUsers: false,
      // for users edit
      selectedUser: null,
      selectedUserIndex: 0,
      contentFields: [
        "PARAGRAPH",
        "HEADING",
        "logo",
        "INITIAL",
        "my-signature",
        "TABLE",
      ],
      userFields: {
        first_name: "First Name",
        last_name: "Last Name",
        company_name: "Company",
        address: "Address",
        phone: "Phone",
        email: "Email",
        full_name: "Full Name",
        title: "Title",
      },
      saveAction: "",
      isTemplate: false,
      templateTitle: "",
      isTemplateToSave: false,
      isTemplateForm: {
        documentTitle: "",
        agreed: "",
      },

      isShowPreview: false,
      showSignErrorElementDialog: false,

      sendDoucumentRedirection: false,

      userDocumentFormData: {
        valid_from: this.$moment().startOf("day").toString(),
        expires_at: this.$moment().endOf("day").toString(),
        e_signature_required: "",
        e_signature_value: "",
      },
      docSettingsVisible: false,
      documentSendStatus: false,
      // table
      selectedHeader: "",
      selectedHeaderIndex: "",
      document_url_path: "",
      tagType: ["success", "info", "", "warning", "danger"],
      fontTypes: [],
      font_Size: 16,
      fontSizes: [
        {
          value: 8,
          label: 8,
        },
        {
          value: 10,
          label: 10,
        },
        {
          value: 12,
          label: 12,
        },
        {
          value: 14,
          label: 14,
        },
        {
          value: 16,
          label: 16,
        },
        {
          value: 18,
          label: 18,
        },
        {
          value: 20,
          label: 20,
        },
        {
          value: 22,
          label: 22,
        },
        {
          value: 24,
          label: 24,
        },
        {
          value: 26,
          label: 26,
        },
        {
          value: 28,
          label: 28,
        },
        {
          value: 30,
          label: 30,
        },
        {
          value: 32,
          label: 32,
        },
        {
          value: 36,
          label: 36,
        },
        {
          value: 48,
          label: 48,
        },
        {
          value: 56,
          label: 56,
        },
        {
          value: 72,
          label: 72,
        },
        {
          value: 84,
          label: 84,
        },
        {
          value: 96,
          label: 96,
        },
      ],
      value: "",
      uploadImageLoading: false,
      imgData: null,
      imgDataWidth: 0,
      imgDataHeight: 0,
      sendDoucumentMailRedirection: false,
      sendDoucumentSettingsRedirection: false,
      docSettingsVisibleAndSend: false,
      mailCCSendTo: [],
      mailSendTo: [],
      setMaxDate: "",
      setMinDate: "",
      mailContent: "Requesting esign - ",
      mailSubject: "",
      sentDocumentSuccess: false,
      sender_auto_fill: false,
      unWantedFields: [
        "INITIAL",
        "SIGNATURE",
        // "ATTACHMENT",
        "IMAGE",
        "HEADING",
        "PARAGRAPH",
        "my-signature",
        "MY_INITIAL",
        "DATE_SIGNED",
        "CHECKBOX",
        "FORMULA",
      ],
      attachmentLoading: false,
      selectedAttachmentFile: {
        document_name: "",
        attachment_url: "",
        valid_from: "",
        document_number: "",
        expires_on: "",
        title: "",
        description: "",
        activeDetails: [],
      },
      senderEmptyfields: [],
      senderEmptyfieldsModal: false,
      issenderFinishDocument: true,
      senderFilledAllFields: false,
      isUserHasSign: true,
      replaceDocumentModel: false,
      files: [],
      replaceDocumentLoading: false,
      logoError: "",
      fieldsFormUpload: {
        title: "",
        type: "CUSTOM",
        file_ref_id: "",
        selectedFile: "",
        selectedFileName: "",
      },
      isInitialRender: true,
      scale: 1,
      docPages: 0,
      pdfPages: 1,
      isRightRotate: false,
      isLeftRotate: false,
      rotations: [],
      allPdfData: [],
      document_url_paths: [],
      total_documents: 1,
      currentActivatePage: 0,
      currentActivateDoc: 0,
      deleted_pages: [],
      currentSelectedPageIndex: -1,
      currentSelectedDocIndex: -1,
      isEditingDocument: false,
      isAddingPage: false,
      isDeletingPage: false,
      affectedFields: [],
      isMergeDocument: false,
      reservedLabels: [
        "first name",
        "last name",
        "email",
        "title",
        "address",
        "phone",
        "company",
        "full name",
      ],
      canRename: false,
      textHighlightStyle: "",
      currentActiveText: {},
      textHighlightVisible: false,
      isEditingText: false,
      selectedText: {},
      selectedTextIndex: -1,
      selectedTextPageIndex: -1,
      selectedTextDocIndex: -1,
      selectedTextInPdf: "",
      selectedTextRation: 1,
      pdfFontTypes: ["Helvetica", "TimesRoman"],
      isEarserMode: false,
      isEarserDraw: false,
      isEarserSelect: false,
      startingX: -1,
      startingY: -1,
      endingX: -1,
      endingY: -1,
    };
  },
  computed: {
    ...mapGetters("configurableDocuments", [
      "getConfigurableDocumentUpdateStatus",
      "getConfigurableDocumentData",
      "getConfigurableDocumentErrors",
      "getConfigurableDocumentDataById",
      "getConfigurableDocumentExpirationSettigns",
    ]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getInitialUrl",
      "getUserSignature",
      "getDefaultDateFormat",
      "getActiveWorkspace",
      "getUserType",
    ]),
    ...mapGetters("fileUpload", [
      "getFontTypes",
      "getUploadFileDataStatus",
      "getAddPageStatus",
      "getCopyDocumentUrl",
      "getFetchPreSignedUrlStatus",
    ]),
    ...mapGetters("documents", [
      "getSingleDocumentData",
      "getDraftDocumentUpdateStatus",
      "getDocumnetSentDocumnet",
      "getDocumentSendStatus",
      "getEmployeeDocumentUsers",
      "getDocumentSendErrors",
    ]),
    ...mapGetters("settings", ["getApplicationSettings"]),
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    ...mapGetters("employeeData", ["getUserSignature"]),
    ...mapGetters("entities", [
      "getEntityDataByQuery",
      "getCompanyEntityDetails",
    ]),
    ...mapGetters("templatesData", [
      "getTemplatesData",
      "getAddNewRepeatableDataStatus",
      "getAddNewRepeatableData",
      "getAddNewRepeatableDataErrors",
    ]),
    ...mapGetters("company", ["getCompanyDetails"]),
    getTableHeaders() {
      return this.selectedItem.headers;
    },
    getDocUsers() {
      let users = [];
      this.documentUsers.forEach((user) => {
        if (user.first_name) {
          users.push(user);
        }
      });
      return users;
    },
    getLogoUrl() {
      return appConfig.S3_BUCKET_URL + "/" + this.getCompanyProfileData.logo;
    },
    getBackgroundImage() {
      return (path) => appConfig.S3_BUCKET_URL + "/" + path;
    },
  },
  async mounted() {
    this.requestDocumentId = this.$route.params.request_document_id;
    await this.fetchRequestDocumentData();

    // await Promise.all([
    //   this.fetchCompanyDetails(),
    //   this.fetchRequestDocumentData(),
    // ]);
    // await this.fetchDocumentSettings({
    //   document_id: this.$route.params.employee_document_id,
    // });
    // this.handleScroll();
  },
  methods: {
    download(data) {
      // Creating a Blob for having a csv file format
      // and passing the data with type
      const blob = new Blob([data], { type: 'text/csv' });

      // Creating an object for downloading url
      const url = window.URL.createObjectURL(blob)

      // Creating an anchor(a) tag of HTML
      const a = document.createElement('a')

      // Passing the blob downloading url
      a.setAttribute('href', url)

      // Setting the anchor tag attribute for downloading
      // and passing the download file name
      a.setAttribute('download', 'download.csv');

      // Performing a download with click
      a.click()
    },

    csvmaker(data) {
      // Empty array for storing the values
      let csvRows = [];
      const headers = [];
      const values = [];
      for(var i = 0; i < data.length; i++) {
        // fieldName
        // value
        
        if(data[i].value) {
          headers.push(data[i].fieldName);
          values.push(data[i].value);
        }
      }
      csvRows.push(headers.join(','));
      csvRows.push(values.join(','))
  
      // Returning the array joining with new line
      return csvRows.join('\n')
    },

    async handleDownloadCSV() {
      const csvdata = this.csvmaker(this.documentData.parsed_data_Obj.formFields);
      this.download(csvdata);
    },
    getTempTitle(title) {
      return title.slice(0, 24);
    },
    async fetchCompanyDetails() {
      let id =
        this.getActiveWorkspace &&
        this.getActiveWorkspace.company_id &&
        this.getActiveWorkspace.company_id._id
          ? this.getActiveWorkspace.company_id._id
          : this.getActiveWorkspace.company_id;
      await this.$store.dispatch("company/fetchCompany", id);
    },
    toDataUrl(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        callback(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
    handleScroll() {
      let scrollbarEl = this.$refs.scrollbar.wrap;
      scrollbarEl.onscroll = () => {
        let pageBreaks = [];
        let h = 0;
        let maxLength = document.getElementById("wrapper").childNodes.length;
        for (let i = 0; i < maxLength; i++) {
          h = h + document.getElementById("wrapper").childNodes[i].height;
          pageBreaks.push(h);
        }
        let pageNumber = pageBreaks.findIndex((page, index) => {
          if (scrollbarEl.scrollTop <= page + index * 6) {
            return page;
          }
        });
        if (document.getElementById(`canvas_page_${pageNumber + 1}`)) {
          // document.getElementById(`canvas_page_${pageNumber+1}`).focus();
          this.currentActivatePage = pageNumber + 1;
        }
      };
    },
    hideElementById(elId) {
      var line = document.getElementById(elId);
      line.style.display = "none";
    },
    ...mapActions({
      fetchDocumentSettings: "documents/fetchDocumentSettings",
    }),
    gotoViewDocument() {
      // let draftId = this.getDraftDocumentCreateData.data._id;
      this.$router.push({
        name: "employee-documents-custom-document",
        params: {
          employee_document_id: this.getDocumnetSentDocumnet._id,
        },
      });
    },
    async closeSettingsAndSend() {
      this.docSettingsVisible = false;
      if (this.docSettingsVisibleAndSend) {
        this.sendDocument();
      }
    },
    handleCloseDialog() {
      this.$router.push({
        name: "Dashboard",
      });
    },
    fileError() {
      this.$alert("File Supported:JPG,PNG,JPEG,Gif");
    },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
    async sendWithSubmit() {
      if (this.elements.length == 0) {
        this.$message.error("Please add fields to send document");
      } else if (this.documentName.length == 0) {
        this.$message.error("Please add document title");
      } else {
        let checkSign = await this.checkSignUser();
        if (checkSign) {
          let isAllUsersConfigured = true;
          this.documentUsers.forEach((user) => {
            if (user && !user.email) {
              isAllUsersConfigured = false;
            }
          });
          if (isAllUsersConfigured) {
            this.documentSend(true);
          } else {
            this.submitEvent = true;
            this.addDocumentUsers = true;
          }
        }
      }
    },
    async fetchCompanyDocument() {
      this.loading = true;
      this.documentId = this.$route.params.document_id;
      this.configurableDocumentId = this.$route.params.configurable_document_id;
      let params = {
        id: this.documentId,
        name: this.documentName,
        title: this.documentName,
      };
      await this.$store.dispatch(
        "companyDocuments/fetchCompanyDocumentById",
        params
      );
      await this.$store.dispatch("configurableDocuments/clearErrors");
      if (this.configurableDocumentId) {
        await this.fetchDocumentData();
      } else {
        this.documentName = this.getCompanyDocumentDataById.title;
        this.backgroundImage = this.getBackgroundImage(
          this.getCompanyDocumentDataById.document_background ||
            this.getCompanyDocumentDataById.document_backgrounds[0]
        );

        this.backgroundImages =
          this.getCompanyDocumentDataById.document_backgrounds.map((e) => {
            return this.getBackgroundImage(e);
          });
      }
      this.loading = false;
    },
    goBack() {
      this.$router.go(-1);
    },
    backToPrevious() {
      this.dialogVisible = false;
      this.$router.go(-1);
    },
    getHeadingStyles(item) {
      return `color:${item.font.color};font-family:${item.font.name}`;
    },
    getImageStyle(item) {
      let style = `width:${item.width}px;;height:${item.height}px;`;
      return style;
    },
    // docuement methods
    async fetchRequestDocumentData() {
      this.loading = true;
      let res = await axios.get(`/request-documents/${this.requestDocumentId}`)
      console.log('>>> fetchRequestDocumentData(), requestDocumentId: ', this.requestDocumentId);
      console.log('>>> fetchRequestDocumentData(), res: ', res);
      this.loading = false;
      if (res.data.success) {
        this.documentData = res.data.data;
        this.documentData.downloadUrl = res.data.downloadUrl;
        this.documentData.parsed_data_Obj = JSON.parse(this.documentData.parsed_data);
        this.documentName = res.data.data.title;
        console.log('>>> fetchRequestDocumentData(), documentData: ', this.documentData);
      }
    },
    getTimeZone() {
      let date1 = new Date();
      var sign = date1.getTimezoneOffset() > 0 ? "-" : "+";
      var offset = Math.abs(date1.getTimezoneOffset());
      var hours =
        Math.floor(offset / 60) < 10
          ? "0" + Math.floor(offset / 60)
          : Math.floor(offset / 60);
      var minutes = offset % 60 < 10 ? "0" + (offset % 60) : offset % 60;
      let timezoneoffset = sign + hours + ":" + minutes;
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return timezone + ", UTC" + timezoneoffset;
    },
  },

  watch: {
    documentUsers() {
      if (this.documentUsers) {
        if (this.documentUsers.length && this.documentUsers.length == 1) {
          this.recipientSelection = this.documentUsers[0].value;
        } else {
          let nonCCUser = this.documentUsers.find((user) => {
            return user.user_type != "CC";
          });
          this.recipientSelection =
            nonCCUser && nonCCUser.value ? nonCCUser.value : null;
        }
        this.getRecipientSelection();
      }
    },
    recipientSelection() {
      this.getRecipientSelection();
    },
    elements: {
      handler: function () {
        // bus.$emit("formula-updated");
      },
      deep: true,
    },
    async "$route.params.configurable_document_id"() {
      window.location.reload();
    },
    async "$route.name"() {
      window.location.reload();
    },
  },
  beforeDestroy() {
    // bus.$off("entityData-updated");
    this.$store.commit(
      "configurableDocuments/setConfigurableDocumentUpdateStatus",
      false,
      {
        root: true,
      }
    );
    this.$store.commit(
      "configurableDocuments/setConfigurableDocumentData",
      null,
      {
        root: true,
      }
    );
    this.$store.commit(
      "configurableDocuments/setConfigurableDocumentErrors",
      null,
      {
        root: true,
      }
    );
    // this.$store.commit(
    //   "configurableDocuments/setConfigurableDocumentDataById",
    //   null,
    //   {
    //     root: true,
    //   }
    // );
    this.$store.commit(
      "configurableDocuments/setConfigurableDocumentExpirationSettigns",
      null,
      {
        root: true,
      }
    );
    this.$store.commit("fileUpload/setFontTypes", null, {
      root: true,
    });
    this.$store.commit("fileUpload/setUploadFileDataStatus", null, {
      root: true,
    });
    this.$store.commit("fileUpload/setAddPageStatus", null, {
      root: true,
    });

    this.$store.commit("documents/setSingleDocumentData", null, {
      root: true,
    });
    this.$store.commit("documents/setDraftDocumentUpdateStatus", null, {
      root: true,
    });
    this.$store.commit("documents/setDocumnetSentDocumnet", null, {
      root: true,
    });
    this.$store.commit("documents/setDocumentSendStatus", null, {
      root: true,
    });
    this.$store.commit("documents/setEmployeeDocumentUsers", null, {
      root: true,
    });
    this.$store.commit("settings/setApplicationSettings", null, {
      root: true,
    });
    this.$store.commit("entities/setEntityDataByQuery", null, {
      root: true,
    });
    this.$store.commit("entities/setCompanyEntityDetails", null, {
      root: true,
    });
  },
};
</script>

<style lang="scss" scoped>
.document-fields {
  .document-fields-header-item {
    color: #f754a2;
    padding: 20px;
    border-bottom: 2px solid #f754a2;
  }
  .document-fields-content {
    .document-fields-item {
      .document-fields-item-inner {
        padding: 20px;
        border-left: 2px solid white;
        border-bottom: 2px solid #dbe5ee;
        .document-fields-item-header {
        }
        .document-fields-item-value {
        }
      }

      &:nth-child(8n) {
        .document-fields-item-inner {
          border-left-color: #f754a2;
        }
      }
      &:nth-child(8n+1) {
        border: 1px solide red;
        .document-fields-item-inner {
          border-left-color: #3777ff;
        }
      }
      &:nth-child(8n+2) {
        border: 1px solide red;
        .document-fields-item-inner {
          border-left-color: #03cea4;
        }
      }
      &:nth-child(8n+3) {
        .document-fields-item-inner {
          border-left-color: #ee964b;
        }
      }
      &:nth-child(8n+4) {
        .document-fields-item-inner {
          border-left-color: #67ce3a;
        }
      }
      &:nth-child(8n+5) {
        .document-fields-item-inner {
          border-left-color: #56ccf2;
        }
      }
      &:nth-child(8n+6) {
        .document-fields-item-inner {
          border-left-color: #2d9cdb;
        }
      }
      &:nth-child(8n+7) {
        .document-fields-item-inner {
          border-left-color: #9b51e0;
        }
      }
    }
  }
}

.ankaCropper__navigation a:nth-child(4n + 4) {
  display: none !important;
}
.ankaCropper__navigation a:nth-child(5n + 5) {
  display: none !important;
}
.text-added {
  position: absolute !important;
}
.text-highlight {
  border: red solid 1px;
  position: absolute !important;
  // background: transparent !important;
  top: 0;
  input {
    white-space: nowrap !important;
    vertical-align: baseline !important;
    width: 100% !important;
    height: 100% !important;
    border: none !important;
    // background: transparent !important;
    resize: none !important;
    overflow: hidden;
    color: inherit;
  }
  .field-textarea {
    &.el-input-nowrap {
      el-input {
        white-space: nowrap !important;
      }
    }
    height: 100% !important;
    &.el-input {
      vertical-align: baseline !important;
      display: table !important;
    }
  }
  &:hover:before {
    visibility: visible;
    opacity: 1;
  }
  el-input .el-input__inner {
    padding: 0px 5px;
    width: 100% !important;
    height: 100% !important;
    border: none !important;
    background: transparent !important;
    resize: none !important;
    overflow: hidden;
    color: inherit;
  }
  .overlay {
    position: absolute;
    left: 5px !important;
    top: 0px !important;
    width: 100%;
    height: 100%;
    z-index: 5;
    padding: 0px !important;
  }
  .overlay {
    overflow: hidden;
    white-space: nowrap;
    width: 82% !important;
  }
  .edit-icon {
    background: #f754a2;
    position: absolute !important;
    // widows: 5px;
    // height: 5px;
  }
  .save-icon {
    background: #55ff00;
    position: absolute !important;
    left: 20px;
  }
  .text-icon {
    background: #006aff;
    position: absolute !important;
    left: 40px;
  }
}
.name-types {
  margin-top: 2%;
  .filename {
    height: 56px;
    margin-top: 1%;
    cursor: pointer;
    background: #ffffff;
    border: 1px solid #dfe3eb;
    box-sizing: border-box;
    border-radius: 2px;
    .el-upload-list__item-name {
      margin-top: 1em;
      .img-active {
        visibility: hidden;
        width: 6px !important;
        margin-right: 10px;
        margin-left: 10px;
        position: relative;
        top: 3px;
      }
      .img-fluid {
        position: relative;
        top: 5px;
        width: 18.43px !important;
      }
    }
    // .delete-doc {
    //   visibility: hidden;
    // }
    .direction-buttons {
      display: flex;
      visibility: hidden;
      position: relative;
      top: -35%;
      left: 97%;
      .directions {
        position: relative;
        right: 10%;
        top: -10px !important;
      }
    }
    &:hover {
      .img-active {
        visibility: visible;
        width: 6px !important;
        margin-right: 10px;
        margin-left: 10px;
        position: relative;
        top: 3px;
      }
      .direction-buttons {
        display: flex;
        visibility: visible;
        position: relative;
        top: -35%;
        left: 97%;
        .directions {
          position: relative;
          right: 10%;
          top: -10px !important;
        }
      }
      // .delete-doc {
      //   visibility: visible;
      //   position: relative;
      //   top: -60%;
      //   left: 97%;
      // }
    }
  }
}
.send-btn {
  @media (max-width: 767.98px) {
    margin-left: 4px !important;
  }
}
.stbtn {
  border-radius: 0;
  border: none !important;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
  @media (max-width: 767.98px) {
    padding: 2px;
  }
}
.avatar-uploader {
  width: 100% !important;
  height: 70px !important;
  border: 1px dashed #409eff;
  background-color: #f7f9ff;
  text-align: center;
  .upload-attchment-box {
    display: flex;
    // padding: 25px;
    h4 {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #4b545c;
    }
  }
}
.el-tag {
  border-radius: 20px;
  padding: 4px;
  line-height: 1;
  height: 30px;
  width: 30px;
  height: auto;
}
element.style {
  transform: translate(73px, 155.5px);
  // z-index: auto;
  user-select: auto;
  background: unset !important;
  color: rgb(0, 0, 0);
  width: 877px;
  height: 165px;
}
.form-builder {
  width: 950px;
  margin: 1.5em auto;
  height: 100%;
  border-radius: 4px;
  background-color: #ffffff;
  border: none;
  // -webkit-box-shadow: 0 0px 5px rgba(0, 0, 0, 0.15);
  // box-shadow: 0 0px 5px rgba(0, 0, 0, 0.15);
  .vdr {
    border: 1px solid rgba(#f36371, 0.5);
    padding: 2px 5px;
  }
  .form-image-holder {
    width: 100%;
    height: 100%;
    // div {
    //   -webkit-box-shadow: 0 0px 5px rgba(0, 0, 0, 0.15);
    //   box-shadow: 0 0px 5px rgba(0, 0, 0, 0.15);
    //   width: 100%;
    //   height: 100%;
    //   background: #eee;
    //   canvas {
    //     width: 100%;
    //     height: 100%;
    //   }
    // }
  }

  .form-fields-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .each-element img {
      width: 100%;
    }
  }
}
#drag-template-background {
  // .img-fluid {
  //   height: auto !important;
  // }

  .inner-navbar {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    z-index: 100;
    background-color: #ffffff;
    border: 1px solid #efefef;
    margin-bottom: 0 !important;
    & > .d-flex {
      gap: 10px;
      .back-btn {
        height: inherit;
      }
    }
    .left-block {
      .el-input__inner {
        min-width: 250px;
        max-width: 300px;
        background: #fff;
        height: 47px;
      }
    }
  }
  .form-fields-set {
    z-index: 99;
    height: calc(100vh - 85px);
    overflow-y: scroll;
    background-color: #ffffff;
    padding: 1.25em 1em;
    border-radius: 0px;
  }
  .document-editor-container {
    .form-field-item {
      display: inline-block;
      margin-top: -15px;
    }
    .form-field-item-emtpy {
      display: inline-block;
      height: 40px;
    }
    .fields-navigators {
      // float: right;
      // margin-top: 2%;
      position: absolute;
      top: 0px;
    }
    .text-editot-btn-active {
      border: 1px solid #a5a3a3cc;
      background: #dad9d9;
    }
    .text-editor-color {
      position: relative;
      //top: 15px;
      margin-left: 5px;
    }
    .text-editot-btn {
      border: none;
      background: transparent;
    }
    .text-editot-slt-size {
      width: 65px;
      background-color: transparent;
    }
    .text-editot-slt input {
      border: none;
    }
    td {
      padding-top: 0;
      vertical-align: top;
      &:nth-child(1) {
        width: 20%;
      }
      &:nth-child(2) {
        width: 80%;
      }
    }
    .inputs-list {
      background: #fff;
      padding: 15px;
      box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.06),
        0 1px 0px rgba(0, 0, 0, 0.08);
      border-radius: 5px;
      .el-button {
        border: none;
        background: rgba(236, 245, 255, 0.7);
        &:hover {
          background: rgba(236, 245, 255, 1);
        }
      }
      li {
        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }
  }
  .form-image {
    max-width: 770px;
    min-height: 1000px;
    background-color: #e6dcdc;
  }
  .el-input__inner {
    display: table !important;
  }

  .form-holder {
    width: 100%;
    position: relative;
    .el-input__inner,
    .el-textarea__inner {
      // height: 100% !important;
      // min-height: 30px !important;
      // line-height: inherit !important;
    }
    input[type="checkbox"] {
      margin: 0 !important;
    }

    // .draggable.resizable {
    //   position: absolute;
    //   z-index: 100;
    // }
  }

  .sample-form1 {
    position: relative;
    width: 100%;
    max-height: 91vh;
    overflow: scroll;
    border: 1px solid #ccc;
    margin-left: 20px;
    margin-bottom: 45px;

    ///
    .each-page {
      position: relative;
      width: 900px;
      display: block;
      height: 800px;
      overflow-y: auto;
      .form-image {
        height: 800px;
        position: absolute;
        width: 850px;
        top: 0;
        left: 0;
      }
      .form-fields {
        height: 800px;
        position: absolute;
        width: 850px;
        top: 0;
        left: 0;
        z-index: 100;
      }
      .page-image {
        width: 850px;
        margin: 0 auto;
      }
    }

    // .draggable.resizable {
    //   position: absolute;
    //   z-index: 100;
    // }
  }

  .color-btn {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin: 10px;
    &.red {
      background-color: red;
    }
    &.black {
      background-color: black;
    }

    &.green {
      background-color: green;
    }
  }

  // Fields
  .scrollable-container {
    .fieldSet__box {
      margin-bottom: 1em;
      // padding-bottom: 1em;
      // border-bottom: 1px solid #efefef;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      .draggable {
        cursor: move; /* fallback: no `url()` support or images disabled */
        cursor: -webkit-grab; /* Chrome 1-21, Safari 4+ */
        cursor: -moz-grab; /* Firefox 1.5-26 */
        cursor: grab; /* W3C standards syntax, should come least */
      }

      .draggable:active {
        cursor: -webkit-grabbing;
        cursor: -moz-grabbing;
        cursor: grabbing;
      }
      .field-element {
        position: relative;
        padding: 0.5em 1em;
        // width: 94%;
        display: grid;
        grid-template-columns: 35px 1fr;
        align-items: center;
        font-size: 0.5em;
        white-space: normal;
        line-height: 1;
        background-color: #f9f9f9;
        border: 1px solid #f1f1f1;
        height: 40px;
        color: #606266;
        border-radius: 3px;
        &:focus {
          color: #f36371;
          border-color: #fdcfd4;
          background-color: #ffe9ec;
        }
        img {
          max-height: 25px;
          padding-right: 10px;
        }
        .name {
          padding-left: 10px;
          line-height: 1.3;
          font-size: 0.8rem;
          text-align: left;
          font-weight: 500;
          i {
            width: 100%;
            font-size: 1em;
            padding-bottom: 0.5em;
          }
        }
      }
    }
  }
  // .draggable {
  //   height: auto !important;
  //   background: rgba(236, 245, 255, 0.48);
  // }
}
</style>

<style lang="scss">
.ankaCropper__navigation a:nth-child(4n + 4) {
  display: none !important;
}
.ankaCropper__navigation a:nth-child(5n + 5) {
  display: none !important;
}
.change-document-dlg {
  .el-dialog__body {
    scrollbar-width: none !important;
  }
}
.form-fields-holder {
  .draggable {
    z-index: 100 !important;
  }
  #svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .line {
    stroke-width: 1px;
    stroke: rgb(9, 60, 230);
    z-index: 200 !important;
  }
}

#drag-template-background {
  .document-editor-container {
    .content-fields {
      textarea.el-textarea__inner {
        min-height: 10px !important;
        // line-height: 15px !important;
        display: table !important;
      }
    }
  }
}
textarea {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
}
.content-fields {
  min-width: 15px !important;
  min-height: 15px !important;
  .field-textarea {
    &.currency-input {
      width: 100% !important;
      height: 100% !important;
      border: none !important;
      border-color: transparent !important;
      background: transparent !important;
    }
    &.currency-input:focus {
      border: none !important;
      outline: none !important;
    }
    &.textarea-nowrap {
      textarea {
        white-space: nowrap !important;
      }
    }
    &.textare-background {
      textarea {
        padding: 0 !important;
      }
    }
    height: 100% !important;
    &.el-textarea {
      vertical-align: baseline !important;
    }
  }
  .overlay {
    overflow: hidden;
    white-space: nowrap;
    width: 82% !important;
  }
  // position:relative; // for tooltip
  &[data-text*="DATE "] {
    background: none !important;
  }
  &:before {
    content: attr(data-text);
    position: absolute;
    top: -25px;
    -webkit-transform: translateY(-50%);
    left: -17px;
    margin-left: 15px;
    width: 142px;
    height: 10px;
    padding: 10px;
    border-radius: 9px;
    background: #555;
    color: white;
    text-align: center;
    line-height: 10px;
    visibility: hidden;
    opacity: 0; /* define initial transition property */
    transition: opacity 1s;
  }
  &:after {
    content: "";
    position: absolute;
    top: -10px;
    left: 47px;
    margin-left: -11px;
    border-width: 9px 9px 10px 0px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
    visibility: hidden;
    opacity: 0; /* define initial transition property */
    transition: opacity 1s;
  }
  &:hover:before {
    visibility: visible;
    opacity: 1;
  }
  &:hover:after {
    visibility: visible;
    opacity: 1;
  }
  &:focus-visible {
    outline: none !important;
  }
  &.el-textarea {
    width: 100% !important;
    height: 100% !important;
  }
  padding: 0 !important;
  &.my-active-class {
    border-width: 2px !important;
    background: transparent !important;
    .overlay {
      display: none;
    }
  }
  &[has-value="1"] {
    background: transparent !important;
  }
  &[has-content="1"] {
    background: transparent !important;
  }
  &[has-background="0"] {
    background: transparent !important;
  }
  &[is-border-none="1"] {
    border: none !important;
  }
  & > p {
    &:last-child {
      margin: 12px 7px;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  & > div {
    &:last-child {
      width: 100% !important;
      height: 100% !important;
      background: none;
      textarea.el-textarea__inner {
        padding: 0px 5px;
        width: 100% !important;
        height: 100% !important;
        border: none !important;
        background: transparent !important;
        resize: none !important;
        overflow: hidden;
        color: inherit;
      }
      .overlay {
        position: absolute;
        left: 5px !important;
        top: 0px !important;
        width: 100%;
        height: 100%;
        z-index: 5;
        padding: 0px !important;
      }
      .el-select {
        width: 100% !important;
        height: 100% !important;
        .el-input {
          width: 100% !important;
          height: 100% !important;
          font: inherit;
          .el-input__inner {
            width: 100% !important;
            height: 100% !important;
          }
        }
        .el-input__suffix-inner {
          i {
            line-height: 100% !important;
          }
        }
        .el-tag {
          height: 100%;
          font: inherit;
          // .el-select__tags-text {
          //   font: inherit;
          // }
        }
      }
      .el-date-editor {
        width: 100% !important;
        height: 100% !important;
        display: flex;
        flex-direction: row-reverse;
        font: inherit;
        background: none;
        .el-input__inner {
          width: 100%;
          height: 100%;
          font: inherit;
          border: none;
        }
        .el-input__prefix {
          position: relative;
          i {
            line-height: 100%;
            display: inline !important;
          }
        }
      }
      min-width: 17px;
      min-height: 17px;
      input[type="checkbox"] {
        width: 100%;
        height: 100%;
        min-width: 15px;
        min-height: 15px;
      }
      &.yes-or-no {
        // padding-left: 5px;
        .el-radio {
          font: inherit !important;
          .el-radio__label {
            font: inherit !important;
          }
          &:first-child {
            margin-right: 10px !important;
          }
          &:last-child {
          }
        }
      }
      .primary-field {
        text-area.el-textarea__inner {
          background: transparent !important;
        }
      }
      &.image-field {
        // padding: 3px 12px;
        color: inherit;
        position: absolute;
        left: 5px !important;
        top: 5px !important;
        padding: 0px !important;
        width: 80% !important;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
  & > img {
    &.my-signature {
      background: transparent !important;
      width: 100%;
      height: auto;
      visibility: visible !important;
    }
    &.my-initial {
      background: transparent !important;
      width: 100%;
      // height: 100%;
      height: auto;
    }
  }
}
.document-editor-container {
  position: relative;
  height: calc(100vh - 62px);
  overflow: hidden;
  .el-scrollbar {
    height: calc(100vh - 145px);
  }
}
.configure-document {
  .el-collapse-item__header {
    &.is-active {
      background-color: #ffffff;
    }
  }
  .el-collapse-item__content {
    padding-bottom: 10px;
  }
  padding-top: 0 !important;
  background-color: #f8f9fa;
  .handle {
    width: 8px;
    height: 8px;
    background: inherit;
    border: 1px solid inherit;
  }
  .each-element.my-active-class {
    border: 1px dashed #f36371 !important;
  }
  .form-title {
    .el-input {
      position: relative;
      input {
        padding: 0;
        line-height: 1;
        background-color: rgba(255, 255, 255, 0.35);
        border: none;
        font-size: 1.5em;
        height: 60px !important;
        font-weight: 500;
        letter-spacing: -0.5px;
        margin-bottom: 0;
        color: #222222;
        box-shadow: none;
      }
      &::before {
        position: absolute;
        content: "";
        top: 0;
        height: 20px;
        width: 10px;
        //background-image: url(/img/hover-dots.ecfb2fa2.svg);
        background-size: 10px 20px;
        background-repeat: no-repeat;
        background-position: center;
        left: 0px;
        opacity: 0;
        z-index: 10;
        transition: 0.3s all ease-in-out;
        -webkit-transition: 0.3s all ease-in-out;
      }
    }
  }

  .right-block {
    height: inherit;
    display: flex;
    align-items: center;
  }
}
.fields-dialog {
  .el-select {
    width: 100%;
  }
  .el-dialog__body {
    padding-right: 5px !important;
    scrollbar-width: none;
    .el-scrollbar__bar {
      &.is-horizontal {
        height: 0px !important;
      }
    }
    .el-form {
      padding: 0px 13px 5px 0px !important;
    }
  }
}
.form-fields-holder {
  .date-picker-item {
    &.smaller-than {
      .el-input__prefix {
        display: none;
      }
      .el-input__inner {
        padding: 0 !important;
      }
    }
  }
}
@media screen and (max-width: 1455px) {
  .pdf-side-bar {
    display: none !important;
  }
  .document-container-wrapper {
    .document-container {
      .el-row {
        display: flex;
        justify-content: center;
      }
    }
  }
}
.settigns-font {
  font-size: 14px;
}
.from-others {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  .image {
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
    .upload-icon {
      width: 25px;
      height: 25px;
    }
    @media (max-width: 991.98px) {
      margin-bottom: 16px;
    }
  }
}
.tooltip {
  padding: 0 !important;
}
#uploadcontainer {
  margin-top: 10px;
  border-top: 1px dashed #409eff;
}
#parastyle {
  text-align: center;
  margin-top: 8px;
}
.forbiddenstyleThree {
  font-family: Inter, sans-serif;
  font-size: 18px;
  color: #000000;
  font-weight: 400;
  margin-left: 27%;
  line-height: 1.5em;
  margin-bottom: 30px;
}
.forbiddenstyleTwo {
  font-family: Inter, sans-serif;
  font-size: 18px;
  color: #000000;
  font-weight: 400;
  margin-left: 21%;
  // line-height: 1.5em
}
.forbiddenstyle {
  font-family: Inter, sans-serif;
  font-size: 22px;
  margin-left: 28%;
  font-weight: 600;
  color: #000000;
  // line-height: 1.5em
}
</style>
